import {
  Icon,
  MessageBar,
  MessageBarType,
    MessageBarButton,
  Link
} from '@fluentui/react';

const QoSInformationMessage = (props) => {

    return (
        <>
            <MessageBar messageBarType={MessageBarType.severeWarning}>
              {props.MessageText}               
            </MessageBar>                 
        </>
    );
};

export default QoSInformationMessage;