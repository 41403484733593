import Chat from './Chat';

import {
    FluentThemeProvider,
    CallClientProvider,
    CallAgentProvider,
    CallProvider
  } from '@azure/communication-react';

import { useEffect, useState } from 'react';
import { ReportCallInMeeting, ReportCallInProgress } from '../utils/reportCallStatus';
import RecordingMessage from './RecordingMessage';
import { Stack } from '@fluentui/react';
import { useRef } from "react";
import CallingComponents from './UIComponents/CallingComponents';
import QoSInformationMessages from './QoSInformationMessages';
import { clearReJoinSessionData ,setReJoinSessionDataExpiry } from '../service/reconnectCallService';
import { scrollSmoothToBottom} from '../service/autoScrollService';

const ConnectedCallUIComponent = (props) => {

const getChatHistory = useRef(null);
let modalRef = useRef({});


const [showChatModal, setShowChatModal] = useState(false);
const [showNewMessage, setShowNewMessage] = useState(false);
const [chatMessagesCount, setChatMessagesCount] = useState(0);

useEffect(() => {
    modalRef.current.isModalOpen =showChatModal;
},[showChatModal]);

    useEffect( () => {
        onHideVideo(true);
        
        const reportCallMeeting=async()=>{
 
            await ReportCallInMeeting(props.consultationId, props.isGroupCall);
        
          }
          reportCallMeeting();

        const timer = setInterval(() => {
            checkCallStillValidAndUpdate();
        }, 30000);

        // Clear timeout if the component is unmounted
        return () => { 
            clearTimeout(timer);
        }

    },[]);

    


    const checkCallStillValidAndUpdate = async () => {
        var validCheck = await ReportCallInProgress(props.consultationId, props.isGroupCall);
        if (!validCheck)
        {
            clearReJoinSessionData();
            //call no longer valid, disconnect
            props.call.hangUp();
            window.alert("Your call has been disconnected.");
        }
        else{
            setReJoinSessionDataExpiry();
        }
    };
 const hangup=async ()=>{
    if (props.call?.state === 'Connected') { 
        if (window.confirm("Are you sure you want to end the call?")) {
            clearReJoinSessionData();
            props.call.hangUp();   
        }
    }
    else
    {
        clearReJoinSessionData();
        props.call.hangUp();
    }
 }


    const onChatClicked = (() => {
        setShowNewMessage(false);
        setShowChatModal(true);
     
        setChatMessagesCount(0);
        setTimeout(function () {
            scrollSmoothToBottom("chatContainerScrollerClass");
        }, 500);	
    });

    const onHideChat = (() => {
        setShowNewMessage(false);
        setShowChatModal(false);
   
    });

    const onNewMessageReceived =(() => {
        if (modalRef.current.isModalOpen  === false) {
            setChatMessagesCount(prevCount =>prevCount+1);
            setShowNewMessage(true); 
        }
    });

    const onHideVideo = (hidden) => {
        if(document.getElementById("local-render"))
        {
            document.getElementById("local-render").remove();
        }
    };



    const onCopyChatClick = () => {
        console.log("Copy chat");
        getChatHistory.current.copyHistory();
    };

    return (
        <>
  <Stack verticalFill>
                <Stack.Item disableShrink><RecordingMessage call={props.call} portalSettings={props.portalSettings}></RecordingMessage></Stack.Item>
                <Stack.Item disableShrink><QoSInformationMessages call={props.call} ></QoSInformationMessages></Stack.Item>
                <Stack.Item align="center" grow> 
                <FluentThemeProvider>
                    {props.statefulCallClient && <CallClientProvider callClient={props.statefulCallClient}>
                    {props.callAgent && <CallAgentProvider callAgent={props.callAgent}>
                        {props.call && <CallProvider call={props.call}>
                        <CallingComponents hideChat={props.portalSettings.hideChat} onChatClicked={onChatClicked} onHangUp={hangup} showNewMessage={showNewMessage} chatMessagesCount={chatMessagesCount}  
                        copyChatClick={onCopyChatClick} isAuditorium={props.portalSettings.isAuditorium} isGroup={props.portalSettings.isGroup} />
                        </CallProvider>}
                    </CallAgentProvider>}
                    </CallClientProvider>}
                    </FluentThemeProvider>
            </Stack.Item>   
    </Stack>
    <Chat ref={getChatHistory} acsEndpoint={props.acsEndpoint} chatThreadId={props.chatThreadId} token={props.token} showChat={showChatModal} hideChat={onHideChat} onNewMessageReceived={onNewMessageReceived} isShowPatientName={props.portalSettings.isShowPatientName} displayName={props.displayName}></Chat>
     </>   
    );
}

export default ConnectedCallUIComponent;