const setReJoinSessionData = function(props) {

    if (AcsUserDataIsValid(props)) {
        setReJoinSessionDataExpiry();
        setReJoinSessionPatientDisplayName(props.patientDisplayName);
        setAcsUserData(props);
        var url = window.location.protocol + "//" + window.location.host + "/join/" + props.consultationResponse.smsAppendLink;
        localStorage.setItem('ReJoinSession_reconnect_url', url);

    }

  };

  const confirmBeforeClearSessionData  = function() {
    if(window.confirm("Are you sure you don't want to join the existing call")){
      clearReJoinSessionData();
      window.location.reload(); 
    }
  };

 const clearReJoinSessionData  = function() {
    localStorage.removeItem('ReJoinSession_reconnect_url');
    localStorage.removeItem('ReJoinSession_expires_at');
     localStorage.removeItem('ReJoinSession_patientDisplayName');
     localStorage.removeItem('ReJoinSession_acsToken');
     localStorage.removeItem('ReJoinSession_acsEndpointUrl');
     localStorage.removeItem('ReJoinSession_acsUserId');
 };

 const checkReJoinSessionDataExpired = function() {
    
    var expiresAt = localStorage.getItem('ReJoinSession_expires_at');

    if(expiresAt === null){
        return true;
    }
    
    var date = new Date();
    var expired = Date.parse(expiresAt) < date;
    return expired;
  };

  const setReJoinSessionDataExpiry = function(){

    var date = new Date();
    date.setTime(date.getTime() + (5 * 60 * 1000));
    localStorage.setItem('ReJoinSession_expires_at', date);
  }

  const setReJoinSessionPatientDisplayName = function(patientDisplayName){
    if(patientDisplayName != null){
      localStorage.setItem('ReJoinSession_patientDisplayName', patientDisplayName);
    }
  }

    const setAcsUserData = function (props) {

        var token = props.consultationResponse.conferenceNode;
        var endpointUrl = props.consultationResponse.chatEndpointUrl;
        var userId = props.consultationResponse.acsUserId;

        localStorage.setItem('ReJoinSession_acsToken', token);
        localStorage.setItem('ReJoinSession_acsEndpointUrl', endpointUrl);
        localStorage.setItem('ReJoinSession_acsUserId', userId);

    }

    const AcsUserDataIsValid = function (props) {

      var token = props.consultationResponse.conferenceNode;
      var endpointUrl = props.consultationResponse.chatEndpointUrl;
      var userId = props.consultationResponse.acsUserId;

      if(token == undefined && endpointUrl == undefined && userId == undefined){
      
        return false;
      
      }

      return true;

  }


  const getReJoinSessionPatientDisplayName = function(){
    var sessionPatientDisplayName = localStorage.getItem('ReJoinSession_patientDisplayName');
    return sessionPatientDisplayName;
  }

 const getRejoinSessionUrl = function(){

    return localStorage.getItem('ReJoinSession_reconnect_url');

  };

  const getAcsToken = function(){

    return localStorage.getItem('ReJoinSession_acsToken');

  };

  const getAcsEndpointUrl = function(){

    return localStorage.getItem('ReJoinSession_acsEndpointUrl');

  };

  const getAcsUserId = function(){

    return localStorage.getItem('ReJoinSession_acsUserId');

  };

  export { setReJoinSessionData,clearReJoinSessionData ,checkReJoinSessionDataExpired,setReJoinSessionDataExpiry,getRejoinSessionUrl,confirmBeforeClearSessionData,getReJoinSessionPatientDisplayName,getAcsToken,getAcsEndpointUrl,getAcsUserId };