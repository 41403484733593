import React, { useState, useEffect, useRef } from 'react';
import { ChatClient } from "@azure/communication-chat";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
import {
  getTheme, mergeStyleSets, FontWeights, Modal, IIconProps, DefaultButton,
  IconButton, IButtonStyles
} from '@fluentui/react';
import { forwardRef, useImperativeHandle } from "react";
import ChatMessage from './ChatMessage';
import { scrollSmoothToBottom } from '../service/autoScrollService';
import ChatTextArea from './UIComponents/ChatTextArea'
const Chat = forwardRef((props, ref) => {

  let chatClientRef = useRef({});
  const [chatMessages, setChatMessage] = useState([]);
  const [message, setMessage] = useState('');
  const [chatThreadClient, setChatThreadClient] = useState();
  const chatTextAreaRef = useRef();

  useImperativeHandle(ref, () => ({
    copyHistory() {
      let chatTranscript = "";
      chatMessages.forEach(element => {
        let messageDate = new Date(element.createdOn);
        let isFromSelf = (element.sender.communicationUserId === element.recipient.communicationUserId ? true : false);
        let isWebPatient = (element.sender.kind === 'communicationUser') ? true : false;
        let fromString = "";
        if (props.isShowPatientName) {
          fromString = isFromSelf ? "You" : element.senderDisplayName;
        } else {
          fromString = isFromSelf ? "You" : isWebPatient ? 'Web Patient' : "Your Consultant";
        }
        let messageDateFormatted = messageDate.toLocaleDateString() + " " + messageDate.toLocaleTimeString();
        chatTranscript = chatTranscript + messageDateFormatted + ":" + fromString + " - " + element.message + "\n";
      });

      copyToClipboard(filterLinksAndImages(chatTranscript), "The chat transcript has been copied to the clipboard.");
    }
  }));

  function filterLinksAndImages(chatTranscript) {
    var defaultRegex = /(<([^>]+)>)/ig;
    var imageRegex = /<img[^>]*?src\s*=\s*[""']?([^'"" >]+?)[ '""][^>]*?>/g;
    var linkRegex = /[^<]*(<a href="([^"]+)"+[\s]+([^>]+)>([^<]+)<\/a>)/g;
    var newChatTranscript = chatTranscript;

    try {
      chatTranscript.replace(imageRegex, function () {
        var match = Array.prototype.slice.call(arguments, 0, 4);
        newChatTranscript = newChatTranscript.replace(match[0], match[1]);
      });

      newChatTranscript = newChatTranscript.replace(defaultRegex, '');

      chatTranscript.replace(linkRegex, function () {
        var match = Array.prototype.slice.call(arguments, 1, 5);
        newChatTranscript = newChatTranscript.replace(match[3], match[1]);
      });

      return newChatTranscript;

    }
    catch (err) {
      console.log("Error processing Chat transcript" + err);
    }

    return newChatTranscript.replace(defaultRegex, '');
  }

  useEffect(() => {

    try {

      let chatClient = new ChatClient(props.acsEndpoint, new AzureCommunicationTokenCredential(props.token));

      const threadClientAsync = async () => {
        let threadClient = await chatClient.getChatThreadClient(props.chatThreadId);
        if (!props.isShowPatientName) {
          await threadClient.sendMessage({ content: "[Service Message] The Web Patient user is able to receive chat messages sent to this meeting. Messages will not indicate the identity of the person sending them; all messages will appear from a common source." });
        }
        setChatThreadClient(threadClient);


        await chatClient.startRealtimeNotifications();
        chatClient.on("chatMessageReceived", (e) => {
          chatRender(e);
        });
        chatClientRef.current.client = chatClient;
      };

      threadClientAsync();


    }
    catch (error) {
      console.log(error);
    }
    return () => {
      if (chatClientRef.current.client) {
        chatClientRef.current.client.off("chatMessageReceived", (e) => {
          chatRender(e);
        });
      }
    }
  }, []);

  const chatTextAreaComponent = () => {
    if (chatTextAreaRef.current) {
      chatTextAreaRef.current.clickSendMessage();
    }
  };
  function chatRender(e) {
    if (e.message.indexOf("[Service Message]") >= 0) return; //don't do anything web-side with service messages
    if (e.type === "RichText/Media_CallRecording") return; //don't do anything with chat messages about recording happening
    setChatMessage(chatMessages => [...chatMessages, e]);
    var tempDomElement = document.createElement('div');
    tempDomElement.innerHTML = e.message;
    var messageText = tempDomElement.textContent;
    tempDomElement.textContent = '';

    if (messageText.includes("iCalUid") && messageText.includes("modernGroupId") && messageText.endsWith("}")) return; // don't do anything with chat messages that meet this criteria (recordingJson)    
    props.onNewMessageReceived();
    scrollSmoothToBottom("chatContainerScrollerClass");
  }

  const sendMessage = (async () => {
    if (message.replace(/\s/g, '').length > 0) {
      await chatThreadClient.sendMessage({ content: message, senderDisplayName:props.displayName });
      setMessage('');
    }
  });


  function copyToClipboard(val, messageText) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    window.alert(messageText);
  };

  return (
    <div>

      <Modal
        titleAriaId="chatModal"
        isOpen={props.showChat}
        onDismiss={props.hideChat}
        isBlocking={false}
        containerClassName={contentStyles.container}
        scrollableContentClassName="chatContainerScrollerClass"
      >
        <div className={contentStyles.header}>
          <IconButton
            styles={iconButtonStyles}
            iconProps={cancelIcon}
            ariaLabel="Close popup modal"
            onClick={props.hideChat}
          />
        </div>
        <div className={contentStyles.body}>
          <div id="chatContainer">
            {chatMessages.length === 0 ? (<div className="NoChatsYet">No Chats Yet</div>) : (
              chatMessages.map(message => <ChatMessage id={message.id} key={message.id} message={message} isShowPatientName={props.isShowPatientName} displayName={message.senderDisplayName} />)
            )}

          </div>
        </div>
        <div className={contentStyles.footer}>
          {chatThreadClient ?
            (

              <div className="ChatSendBox">
                <ChatTextArea message={message} setMessage={setMessage} sendMessage={sendMessage} ref={chatTextAreaRef}  />
                <DefaultButton className="ChatSendButton" disabled={!message} toggle text="Send" onClick={chatTextAreaComponent} />
                <DefaultButton className="ChatCloseButton" toggle text="Close" onClick={props.hideChat} />

              </div>

            ) : (
              <></>
            )}
        </div>
      </Modal>
    </div>
  );
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    minWidth: '75%',
    minHeight: '75%',
    maxWidth: '80%',
    maxHeight: '80%'
  },
  header: [
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '2px 12px 2px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 1px 50px 1px',
    overflowY: 'hidden',

    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  footer: {
    margin: '0 0 50px 0',
  }
});

const iconButtonStyles: Partial<IButtonStyles> = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


export default Chat;
